<template>
  <v-card class="mb-5">
    <v-row no-gutters class="text-left" v-if="result">
      <v-col cols="12">
        <v-row no-gutters class="align-center">
          <v-col cols="4">
            <div class="text-h5 ml-4">
              <v-icon class="mr-2"> mdi-file </v-icon>{{ file.name }}
              <v-btn icon fab class="ml-2" @click="download(file.url)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="4" />
          <v-col cols="4" class="pa-3 text-right">
            <v-btn icon fab large @click="player.wavesurfer.skipBackward()">
              <v-icon>mdi-skip-backward</v-icon>
            </v-btn>

            <v-btn
              icon
              fab
              x-large
              @click="
                player.status
                  ? player.wavesurfer.pause()
                  : player.wavesurfer.play();
                player.status = !player.status;
              "
            >
              <v-icon
                style="font-size: 56px !important"
                v-if="!player.status"
                x-large
              >
                mdi-play
              </v-icon>
              <v-icon style="font-size: 56px !important" v-else>
                mdi-pause
              </v-icon>
            </v-btn>

            <v-btn icon fab large @click="player.wavesurfer.skipForward()">
              <v-icon>mdi-skip-forward</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="result">
            <v-subheader>
              {{ result.data.summarization }}
            </v-subheader>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <div :id="'player' + index" />
        <v-divider class="mt-4" />
      </v-col>

      <v-col cols="12">
        <v-row no-gutters>
          <v-col
            cols="5"
            style="overflow-x: hidden; overflow-y: scroll; max-height: 400px"
          >
            <v-timeline dense clipped>
              <v-fade-transition group>
                <v-timeline-item
                  v-for="(utterance, indexUtterance) in utterances"
                  :key="utterance.start"
                  class="mb-4 item-timeline"
                  :class="[
                    {
                      scrollInto:
                        currentTime >= utterance.start &&
                        currentTime <= utterance.end
                          ? scrollInto(indexUtterance)
                          : false,
                    },
                    elemClass,
                  ]"
                  @click.native="setCurrentTime(utterance.start)"
                  :color="returnColorSentiment(utterance.sentiment)"
                  fill-dot
                >
                  <template v-slot:icon>
                    <v-icon dark v-if="utterance.speaker == 'Agent'">
                      mdi-headset
                    </v-icon>
                    <v-icon dark v-else> mdi-account </v-icon>
                  </template>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      <template v-for="word in utterance.words">
                        <template v-if="word.sentiment == 'negative'">
                          <span
                            style="color: red"
                            :key="word"
                            :class="{
                              activeWord:
                                currentTime >= word.start &&
                                currentTime <= word.end
                                  ? true
                                  : false,
                            }"
                          >
                            {{ word.punctuated_word }}</span
                          >
                        </template>
                        <template v-else-if="word.sentiment == 'positive'">
                          <span
                            style="color: green"
                            :key="word"
                            :class="{
                              activeWord:
                                currentTime >= word.start &&
                                currentTime <= word.end
                                  ? true
                                  : false,
                            }"
                          >
                            {{ word.punctuated_word }}</span
                          >
                        </template>
                        <template v-else>
                          <span
                            :key="word"
                            :class="{
                              activeWord:
                                currentTime >= word.start &&
                                currentTime <= word.end
                                  ? true
                                  : false,
                            }"
                          >
                            {{ word.punctuated_word }}</span
                          >
                        </template>
                      </template>
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="5"
                      v-text="
                        new Date(utterance.start * 1000)
                          .toISOString()
                          .substr(11, 8)
                      "
                    />
                  </v-row>
                </v-timeline-item>
              </v-fade-transition>
            </v-timeline>
          </v-col>
          <v-col cols="7">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              grow
            >
              <v-tab> Times </v-tab>
              <v-tab> Sentiments </v-tab>
              <v-tab disabled>
                Topics
                <v-chip class="ml-1" style="text-transform: lowercase">
                  soon
                </v-chip>
              </v-tab>
              <v-tab disabled>
                Keywords
                <v-chip class="ml-1" style="text-transform: lowercase">
                  soon
                </v-chip>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-row
                  v-if="result"
                  justify="space-around"
                  no-gutters
                  style="max-height: 354px; overflow-y: scroll"
                >
                  <v-col cols="12">
                    <v-subheader class="text-h5"> Talk </v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-subheader>Overall</v-subheader>
                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.talkTimeMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('caller') == -1 &&
                          elem.metricName.indexOf('agent') == -1
                            ? true
                            : false
                      )"
                    >
                      {{ metric.metricName }}:{{
                        metric.metricValue.toFixed(2)
                      }}
                    </v-subheader>
                  </v-col>

                  <v-col cols="4">
                    <v-subheader>
                      <v-icon>mdi-headset</v-icon>
                    </v-subheader>

                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.talkTimeMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('agent') != -1 ? true : false
                      )"
                    >
                      {{
                        metric.metricName
                          .replace(new RegExp("agent-", "g"), "")
                          .replace(new RegExp("- ", "g"), " ")
                      }}:<label class="ml-3">{{
                        metric.metricValue.toFixed(2)
                      }}</label>
                    </v-subheader>
                  </v-col>

                  <v-col cols="4">
                    <v-subheader>
                      <v-icon>mdi-account</v-icon>
                    </v-subheader>
                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.talkTimeMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('caller') != -1 ? true : false
                      )"
                    >
                      {{
                        metric.metricName
                          .replace(new RegExp("caller-", "g"), "")
                          .replace(new RegExp("-", "g"), " ")
                      }}:<label class="ml-3">{{
                        metric.metricValue.toFixed(2)
                      }}</label>
                    </v-subheader>
                  </v-col>

                  <v-col cols="12">
                    <v-subheader class="text-h5"> Overtalk </v-subheader>
                  </v-col>

                  <v-col cols="4">
                    <v-subheader>Overall</v-subheader>
                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.overtalkMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('caller') == -1 &&
                          elem.metricName.indexOf('agent') == -1
                            ? true
                            : false
                      )"
                    >
                      {{ metric.metricName }}:{{ metric.metricValue }}
                    </v-subheader>
                  </v-col>

                  <v-col cols="4">
                    <v-subheader>
                      <v-icon>mdi-headset</v-icon>
                    </v-subheader>
                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.overtalkMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('agent') != -1 ? true : false
                      )"
                    >
                      {{
                        metric.metricName
                          .replace(new RegExp("agent", "g"), "")
                          .replace(new RegExp("-", "g"), " ")
                      }}:<label class="ml-3">{{ metric.metricValue }}</label>
                    </v-subheader>
                  </v-col>

                  <v-col cols="4">
                    <v-subheader>
                      <v-icon>mdi-account</v-icon>
                    </v-subheader>
                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.overtalkMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('caller') != -1 ? true : false
                      )"
                    >
                      {{
                        metric.metricName
                          .replace(new RegExp("caller", "g"), "")
                          .replace(new RegExp("-", "g"), " ")
                      }}:<label class="ml-3">{{ metric.metricValue }}</label>
                    </v-subheader>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row
                  v-if="result"
                  justify="space-around"
                  class="text-left"
                  no-gutters
                  style="max-height: 354px; overflow-y: scroll"
                >
                  <v-col cols="6">
                    <v-subheader>
                      <v-icon>mdi-headset</v-icon>
                    </v-subheader>

                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.sentimentMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('agent') != -1 ? true : false
                      )"
                    >
                      {{
                        metric.metricName
                          .replace(new RegExp("agent", "g"), "")
                          .replace(new RegExp("-", "g"), " ")
                      }}:<label class="ml-3">{{ metric.metricValue }}</label>
                    </v-subheader>
                  </v-col>

                  <v-col cols="6">
                    <v-subheader>
                      <v-icon>mdi-account</v-icon>
                    </v-subheader>
                    <v-subheader
                      :key="metric.metricName"
                      v-for="metric in result.data.sentimentMetrics.filter(
                        (elem) =>
                          elem.metricName.indexOf('caller') != -1 ? true : false
                      )"
                    >
                      {{
                        metric.metricName
                          .replace(new RegExp("caller", "g"), "")
                          .replace(new RegExp("-", "g"), " ")
                      }}:<label class="ml-3">{{ metric.metricValue }}</label>
                    </v-subheader>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text />
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-skeleton-loader v-else class="mx-auto" type="card" />
  </v-card>
</template>

<script>
import moment from "moment";
import Transcription from "./../models/transcription.js";
import useCallableFunction from "@/hooks/useCallableFunction";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, getFirestore } from "firebase/firestore";

export default {
  name: "RecordingPanel",
  components: {},
  data: () => ({
    elemClass: "",
    currentTime: null,
    pipeline: null,
    utterances: [],
    error: null,
    result: null,
    sentiment: {
      agent: [],
    },
    dialog: false,
    player: {
      wavesurfer: null,
      status: 0,
    },
    tab: 0,
  }),
  props: {
    file: {
      type: File,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    download(url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = this.$props.file.name;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    setCurrentTime(s) {
      this.player.wavesurfer.play(s);
      this.player.status = true;
    },
    scrollInto(index) {
      const elems = document.getElementsByClassName(this.elemClass);
      elems[index].scrollIntoView({ behavior: "smooth", block: "center" });
    },

    async upload(file) {
      const {
        intent,
        topic,
        mode,
        language,
        model,
        API,
        pipeline,
        originEnabled,
        phonenumber,
        callUid,
        callstarttimestamp,
        origin,
      } = this.$props.settings;
      const obj = {
        filename: file.name,
        file_size: file.size,
        intent: intent.join(","),
        topic: topic.join(","),
        mode,
        language,
        model,
        API,
        pipeline,
        origin: originEnabled ? origin : null,
        phonenumber: phonenumber || null,
        callUid: callUid || null,
        callstarttimestamp: moment(callstarttimestamp).toISOString(),
        date: moment().format("YYYYMMDD"),
        dateUpload: moment().format("YYYY-MM-DD HH:mm:ss"),
        userid: this.$store.state.selectedUserID,
      };

      const filePath = `audio-files/${
        obj.userid.split("@")[1]
      }/${moment().format("YYYYMMDD")}/${obj.userid.split("@")[0]}`;
      obj.file_path = filePath;

      const storage = getStorage();
      const storageRef = ref(storage, filePath + `/${obj.filename}`);
      this.pipeline = pipeline;

      try {
        const transcript = new Transcription({
          dateUpload: obj.dateUpload || "",
          filename: obj.filename || "",
          processed: false,
          billed_seconds: 0,
          overtalkMetrics: [],
          sentimentMetrics: [],
          talkTimeMetrics: [],
          utterances: [],
          summarization: "",
          observations: [],
        }).get();

        const db = getFirestore();
        const collectionPath = `companies/${obj.userid.split("@")[1]}/users/${
          obj.userid.split("@")[0]
        }/${obj.date}`;
        const docRef = await addDoc(collection(db, collectionPath), transcript);

        obj.docRef = docRef.id;
        transcript.id = docRef.id;
        this.$emit("uploadTranscript", transcript);
        this.$emit("add_doc");
      } catch (e) {
        console.error("Error adding document: ", e);
      }

      try {
        await uploadBytes(storageRef, file);
        const Speech2Text = useCallableFunction("Speech2Text", {
          timeout: 540000,
        });
        await Speech2Text(obj);
        this.$emit("reload");
      } catch (e) {
        this.$notify({
          group: "feedback",
          duration: -1,
          type: "error",
          title: "UPLOAD",
          text:
            "An error ocurred during the transcription process of file " +
            obj.filename,
        });
        this.$emit("reload");
      }
    },
    returnColorSentiment(sentiment) {
      const color = "primary";

      if (sentiment === "positive") {
        return "green";
      }
      if (sentiment === "negative") {
        return "red";
      }

      return color;
    },
    returnColor(sentiment) {
      const color = "none";

      if (sentiment === "positive") {
        return "#43a04738";
      }
      if (sentiment === "negative") {
        return "#e5393536";
      }

      return color;
    },
  },
  async mounted() {
    this.elemClass = this.$props.index + "-message-timeline";
    await this.upload(this.$props.file);
  },
};
</script>

<style scoped>
.v-timeline-item {
  padding-right: 10px;
}
.rowClick:hover {
  background: lightgray;
  cursor: pointer;
}

.activeWord {
  border-bottom: 1px solid red;
}

.v-timeline-item:hover {
  cursor: pointer;
}
</style>
