var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-5"},[(_vm.result)?_c('v-row',{staticClass:"text-left",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-h5 ml-4"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-file ")]),_vm._v(_vm._s(_vm.file.name)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","fab":""},on:{"click":function($event){return _vm.download(_vm.file.url)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"pa-3 text-right",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"icon":"","fab":"","large":""},on:{"click":function($event){return _vm.player.wavesurfer.skipBackward()}}},[_c('v-icon',[_vm._v("mdi-skip-backward")])],1),_c('v-btn',{attrs:{"icon":"","fab":"","x-large":""},on:{"click":function($event){_vm.player.status
                ? _vm.player.wavesurfer.pause()
                : _vm.player.wavesurfer.play();
              _vm.player.status = !_vm.player.status;}}},[(!_vm.player.status)?_c('v-icon',{staticStyle:{"font-size":"56px !important"},attrs:{"x-large":""}},[_vm._v(" mdi-play ")]):_c('v-icon',{staticStyle:{"font-size":"56px !important"}},[_vm._v(" mdi-pause ")])],1),_c('v-btn',{attrs:{"icon":"","fab":"","large":""},on:{"click":function($event){return _vm.player.wavesurfer.skipForward()}}},[_c('v-icon',[_vm._v("mdi-skip-forward")])],1)],1),(_vm.result)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.result.data.summarization)+" ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":'player' + _vm.index}}),_c('v-divider',{staticClass:"mt-4"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"overflow-x":"hidden","overflow-y":"scroll","max-height":"400px"},attrs:{"cols":"5"}},[_c('v-timeline',{attrs:{"dense":"","clipped":""}},[_c('v-fade-transition',{attrs:{"group":""}},_vm._l((_vm.utterances),function(utterance,indexUtterance){return _c('v-timeline-item',{key:utterance.start,staticClass:"mb-4 item-timeline",class:[
                  {
                    scrollInto:
                      _vm.currentTime >= utterance.start &&
                      _vm.currentTime <= utterance.end
                        ? _vm.scrollInto(indexUtterance)
                        : false,
                  },
                  _vm.elemClass,
                ],attrs:{"color":_vm.returnColorSentiment(utterance.sentiment),"fill-dot":""},nativeOn:{"click":function($event){return _vm.setCurrentTime(utterance.start)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(utterance.speaker == 'Agent')?_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-headset ")]):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account ")])]},proxy:true}],null,true)},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_vm._l((utterance.words),function(word){return [(word.sentiment == 'negative')?[_c('span',{key:word,class:{
                            activeWord:
                              _vm.currentTime >= word.start &&
                              _vm.currentTime <= word.end
                                ? true
                                : false,
                          },staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(word.punctuated_word))])]:(word.sentiment == 'positive')?[_c('span',{key:word,class:{
                            activeWord:
                              _vm.currentTime >= word.start &&
                              _vm.currentTime <= word.end
                                ? true
                                : false,
                          },staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(word.punctuated_word))])]:[_c('span',{key:word,class:{
                            activeWord:
                              _vm.currentTime >= word.start &&
                              _vm.currentTime <= word.end
                                ? true
                                : false,
                          }},[_vm._v(" "+_vm._s(word.punctuated_word))])]]})],2),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"},domProps:{"textContent":_vm._s(
                      new Date(utterance.start * 1000)
                        .toISOString()
                        .substr(11, 8)
                    )}})],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"basil","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Times ")]),_c('v-tab',[_vm._v(" Sentiments ")]),_c('v-tab',{attrs:{"disabled":""}},[_vm._v(" Topics "),_c('v-chip',{staticClass:"ml-1",staticStyle:{"text-transform":"lowercase"}},[_vm._v(" soon ")])],1),_c('v-tab',{attrs:{"disabled":""}},[_vm._v(" Keywords "),_c('v-chip',{staticClass:"ml-1",staticStyle:{"text-transform":"lowercase"}},[_vm._v(" soon ")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.result)?_c('v-row',{staticStyle:{"max-height":"354px","overflow-y":"scroll"},attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"text-h5"},[_vm._v(" Talk ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v("Overall")]),_vm._l((_vm.result.data.talkTimeMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('caller') == -1 &&
                        elem.metricName.indexOf('agent') == -1
                          ? true
                          : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName)+":"+_vm._s(metric.metricValue.toFixed(2))+" ")])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_c('v-icon',[_vm._v("mdi-headset")])],1),_vm._l((_vm.result.data.talkTimeMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('agent') != -1 ? true : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName .replace(new RegExp("agent-", "g"), "") .replace(new RegExp("- ", "g"), " "))+":"),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(metric.metricValue.toFixed(2)))])])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_c('v-icon',[_vm._v("mdi-account")])],1),_vm._l((_vm.result.data.talkTimeMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('caller') != -1 ? true : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName .replace(new RegExp("caller-", "g"), "") .replace(new RegExp("-", "g"), " "))+":"),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(metric.metricValue.toFixed(2)))])])})],2),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"text-h5"},[_vm._v(" Overtalk ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v("Overall")]),_vm._l((_vm.result.data.overtalkMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('caller') == -1 &&
                        elem.metricName.indexOf('agent') == -1
                          ? true
                          : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName)+":"+_vm._s(metric.metricValue)+" ")])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_c('v-icon',[_vm._v("mdi-headset")])],1),_vm._l((_vm.result.data.overtalkMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('agent') != -1 ? true : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName .replace(new RegExp("agent", "g"), "") .replace(new RegExp("-", "g"), " "))+":"),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(metric.metricValue))])])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_c('v-icon',[_vm._v("mdi-account")])],1),_vm._l((_vm.result.data.overtalkMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('caller') != -1 ? true : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName .replace(new RegExp("caller", "g"), "") .replace(new RegExp("-", "g"), " "))+":"),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(metric.metricValue))])])})],2)],1):_vm._e()],1),_c('v-tab-item',[(_vm.result)?_c('v-row',{staticClass:"text-left",staticStyle:{"max-height":"354px","overflow-y":"scroll"},attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-subheader',[_c('v-icon',[_vm._v("mdi-headset")])],1),_vm._l((_vm.result.data.sentimentMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('agent') != -1 ? true : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName .replace(new RegExp("agent", "g"), "") .replace(new RegExp("-", "g"), " "))+":"),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(metric.metricValue))])])})],2),_c('v-col',{attrs:{"cols":"6"}},[_c('v-subheader',[_c('v-icon',[_vm._v("mdi-account")])],1),_vm._l((_vm.result.data.sentimentMetrics.filter(
                      (elem) =>
                        elem.metricName.indexOf('caller') != -1 ? true : false
                    )),function(metric){return _c('v-subheader',{key:metric.metricName},[_vm._v(" "+_vm._s(metric.metricName .replace(new RegExp("caller", "g"), "") .replace(new RegExp("-", "g"), " "))+":"),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(metric.metricValue))])])})],2)],1):_vm._e()],1),_c('v-tab-item',[_c('v-card',[_c('v-card-text')],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-text')],1)],1)],1)],1)],1)],1)],1):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }